<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>
							<a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> 
							<button class="btn btn-primary ml-3">Agent Info</button>
							<button class="btn btn-default ml-3" @click="toRedirect('agent-dashboard')">Dashboard</button>
							<button class="btn btn-default ml-3" @click="toRedirect('agent-order')">Order</button>
							<button class="btn btn-default ml-3" @click="toRedirect('agent-receive')">Receive</button>
						</h6>

					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="agentRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEmail" class="col-sm-2 col-form-label">Email <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="agentRequest.email" @blur="validationRule()"
										class="form-control" id="inputEmail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputCode" class="col-sm-2 col-form-label">Promo Code <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="agentRequest.promoCode" @blur="validationRule()"
										class="form-control" id="inputCode">
									<small class="text-danger">{{validationData.promoCode}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAmount" class="col-sm-2 col-form-label">Promo Amount <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="agentRequest.promoAmount" @blur="validationRule()"
										class="form-control" id="inputAmount">
									<small class="text-danger">{{validationData.promoAmout}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputRate" class="col-sm-2 col-form-label">Commission Rate <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="agentRequest.agentPercentage" @blur="validationRule()"
										class="form-control" id="inputRate">
									<small class="text-danger">{{validationData.agentPercentage}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Password </label>
								<div class="col-sm-10">
									<input type="password" v-model="agentRequest.password" @blur="validationRule()"
										class="form-control" id="inputName">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input" v-model="agentRequest.promoStatus" true-value="1" false-value="0">
											<label for="inputHotdels" class="form-check-label">Promo Status </label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<div class="form-check">
										<div>
											<input type="checkbox" class="form-check-input" v-model="agentRequest.agentStatus" true-value="1" false-value="0">
											<label for="inputHotdels" class="form-check-label">Agent Status </label>
										</div>
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'agentEdit',
		metaInfo: {
            title: "agent Edit",
            titleTemplate: "%s ← SHRE Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentFile: "",
				currentImage: "",
				filter: {
					page: "",
                    orderBy: "",
                    sortKey: "",
                    name: "",
				},
				agentRequest: {
					id: "",
					name: "",
					email: "",
					promoCode: "",
					promoAmount: "",
					agentPercentage: "",
					password : ""
				},
				validationData: {
					name: "",
					email: "",
					promoCode: "",
					promoAmount: "",
					agentPercentage: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				agentUpdateAction: 'agentUpdateAction',
				agentDetailAction: 'agentDetailAction'
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.agentRequest.image = e.target.result
				};
			},
			async getFields(){
				let option = {
					type: "update",
					id: this.agentRequest.id
				}
				await this.agentDetailAction({
					...option
				}).then(res => {
					this.agentRequest.name = res.data.detail.name
					this.agentRequest.email = res.data.detail.email
					this.agentRequest.promoCode = res.data.detail.promoCode
					this.agentRequest.promoAmount = res.data.detail.promoAmount
					this.agentRequest.agentPercentage = res.data.detail.agentPercentage
					this.agentRequest.promoStatus = res.data.detail.promoStatus
					this.agentRequest.agentStatus = res.data.detail.accountStatus
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.agentRequest
				await this.agentUpdateAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'agent-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
			toRedirect(key) {
				let agentId = this.agentRequest.id
                this.$router.replace({ path: key, query: { page: 1, orderBy: 'desc', sortKey: 'created_at', limit: 10, id: agentId, email: '', orderNo: '' } }).catch(()=>{})
            },
			validationRule() {
				let isValidation = []
				if (this.agentRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				
				if (this.agentRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}

				if (this.agentRequest.promoCode == "") {
					this.validationData.promoCode = "The promo code field is required."
					isValidation.push(true)
				} else {
					this.validationData.promoCode = ""
					isValidation.push(false)
				}

				if (this.agentRequest.promoAmount == "") {
					this.validationData.promoAmount = "The promo amount field is required."
					isValidation.push(true)
				} else {
					this.validationData.promoAmount = ""
					isValidation.push(false)
				}

				if (this.agentRequest.agentPercentage == "") {
					this.validationData.agentPercentage = "The commission rate field is required."
					isValidation.push(true)
				} else {
					this.validationData.agentPercentage = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
			this.agentRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>